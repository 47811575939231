$(document).foundation();
$(document).ready(function() {

	if( $('body').hasClass('home') ){
  	//$('#prllx-home-1').parallax({imageSrc: '/img/Accueil_01.jpg', bleed: 50, speed: .5});
		$('.parallax-window').parallax({bleed: 50, speed: .5});
  }

	if( $('body').hasClass('page-template-contact') ){
		var isDraggable = $(window).width() > 1024 ? true : false;
		var mymap = L.map('osm', {dragging: isDraggable, scrollWheelZoom:false}).setView([46.291369,5.494361], 15);

		L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png').addTo(mymap);
		var icon = L.icon({
				    iconUrl: 'http://mq.erythrones.fr/img/1x/picto-gmap.png',

				    iconSize:     [30, 46], // size of the icon
				    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
				});
		var infoContent = '<div class="window-content"><h4>Fruitière des Érythrônes</h4><p>Rue des Champs rouges<br/>39240 Aromas<br/><br/>Tél. 04 74 50 70 08</p></div>';
		L.marker([46.291369,5.494361], {title: 'Fruitière des Érythrônes', icon: icon}).bindPopup(infoContent).addTo(mymap);

		function checkResize(){
			mymap.invalidateSize();
		}

		window.onresize = checkResize;

	}

});